App.Modules = App.Modules || {};
App.Modules.aboutClients = function () {

	var clientRotator = function(data) {

		var images = [],
			imageHolder = [],
			unassignedImages = [],
			lpImages,
			$clientImageHolder = jQuery('.client-image-holder'),
			$clientImages = jQuery('.client-image');

		$clientImageHolder.each(function(i, el) {
			var image = jQuery(el).data('image');
			images.push(image);
		});

		$clientImages.each(function(i, el) {
			imageHolder.push(el);
		});

		// initialize list of unassigned images
		for (lpImages = $clientImages.length; lpImages < $clientImageHolder.length; lpImages++ )
		{
			unassignedImages.push(jQuery($clientImageHolder[lpImages]).data('image'));
		}

		function rotate() {

			var randomImage = unassignedImages.splice( ~~(Math.random()*unassignedImages.length), 1 )[0],
				randomHolder = imageHolder[~~(Math.random()*imageHolder.length)];

			// put the current image back into unassignedImages
			unassignedImages.push(jQuery(randomHolder).children('img').attr('src'));

			jQuery(randomHolder).children('img').fadeOut( function() {
				jQuery(randomHolder).children('img').attr('src', randomImage);
				jQuery(randomHolder).children('img').fadeIn();
			});

			setTimeout(rotate,2500);
		}

		setTimeout(rotate,2500);
	};

	return {
		init: function() { 
			clientRotator();
			return this; 
		},
		events: function() {
			return this;
		}
	};
}();