App.Modules = App.Modules || {};
App.Modules.HomepagePanels = function () {
  // these are option items, just a simple way to
  // keep yourself organized...

    var container = jQuery('.capability-slides'),
      $dots,
      $panels,
      activePanelIndex = 0,
      numPanels,
      isAnimating = false,
      footerVisible = false,

    changePanel = function(data)
    {
      if(! isAnimating ) {
        var delta = data.event.deltaY;
        if (delta > 0) {
          nextPanel();
        } else if (delta < 0) {
          prevPanel();
        }
      }
    },

    changePanelClick = function(data)
    {
      $index = jQuery(data.elem).data('slide');
      goToPanel( $index );
    },

    changePanelKeypress = function(data)
    {
      if( ! isAnimating ) {
        data.event.preventDefault();
        switch (data.key)
        {
          case 32:
            nextPanel();
            break;
          case 38:
            prevPanel();
            break;
          case 40:
            nextPanel();
            break;
          default:
            break;
        }
      }
    },

    checkFooter = function()
    {
    },

    nextPanel = function()
    {
      goToPanel(activePanelIndex + 1);
    },

    prevPanel = function()
    {
      goToPanel(activePanelIndex - 1);
    },

    goToPanel = function(newPanelIndex)
    {
      if( ! isAnimating && newPanelIndex >= 0 && newPanelIndex <= numPanels - 1 && newPanelIndex !== activePanelIndex )
      {
        isAnimating = true;

        var $curPanel = $panels.eq(activePanelIndex),
          $newPanel = $panels.eq(newPanelIndex);

        if( newPanelIndex > activePanelIndex )
        {
          for(var i = (activePanelIndex + 1); i < newPanelIndex; i++)
          {
            $panels.eq(i).addClass('offscreen').addClass('hidden');
          }
          $curPanel.addClass('hidden').removeClass('active');
          $newPanel.removeClass('hidden').removeClass('offscreen').addClass('active');
        }

        if( newPanelIndex < activePanelIndex )
        {
          for(var i = (activePanelIndex + 1); i < newPanelIndex; i++)
          {
            $panels.eq(i).addClass('offscreen').addClass('hidden');
          }
          $curPanel.removeClass('active');
          $newPanel.removeClass('offscreen').removeClass('hidden').addClass('active');
        }

        //navigation dots
        $dots.removeClass('active');
        // -1 because we have the first slide which is not part of the dot navigation
        $dots.eq( newPanelIndex -1 ).addClass('active');

        var t = setTimeout(function () {
          isAnimating = false;
          if( newPanelIndex > activePanelIndex )
          {
            $curPanel.addClass('offscreen');
          }
          activePanelIndex = newPanelIndex;
        }, 1000);
      }
    };

  return {
    init: function() {
      $panels = jQuery('.capability-slide');
      numPanels = $panels.length;
      $dots = jQuery('.dot');
      return this;

    },
    events: function() {

      if ( jQuery( window ).width() >= 1024 ) {

        Events.bind(
          "onwheel" in document.createElement("div") ? "wheel" : // Modern browsers support "wheel"
          document.onmousewheel !== undefined ? "mousewheel" : // Webkit and IE support at least "mousewheel"
            "DOMMouseScroll", '.capability-slides'
        ).to(changePanel, {context: this});
        Events.bind('click', '.dot').to(changePanelClick, this);
        Events.bind('keypress').to(changePanelKeypress, this);
        
      }

      return this;
    }
  };
}();

