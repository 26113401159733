App.Modules = App.Modules || {};
App.Modules.StickyHeader = function () {
     
     var adjustLayout = function()
     { 
        footerHeight = jQuery('.site-footer').outerHeight(true) - 1;
        headerHeight = jQuery('.site-header').outerHeight(true) - 2;

        //jQuery('.site-content').css({'marginBottom': footerHeight});
        jQuery('.site-content').css({'paddingTop': headerHeight });
     };

     var checkTop = function(data) {

      if ( jQuery('body').hasClass('page-template-page-capabilities') && jQuery( window ).width() >= 1024 ) {
        // do nothing
      } else {

        //all other pages
        if ( ! jQuery('body').hasClass('home') ) {
         
         if( jQuery(window).scrollTop() < 100) {
           jQuery(".site-header").removeClass('sticky');
         }
         if( jQuery(window).scrollTop() > 100) {
           jQuery(".site-header").addClass('sticky');
         }

        // homepage
        } else {

          if( jQuery(window).scrollTop() < 1 ) {
           jQuery(".site-header").removeClass('sticky');
         }
          if( jQuery(window).scrollTop() > 200 ) {
           jQuery(".site-header").addClass('sticky');
         }

        }

      }

     }

    var permanentSticky = function(data) {
      jQuery('.site-header').css({'position' : 'fixed'});
    }

    var goSticky = function(data) {

      if ( jQuery('body').hasClass('page-template-page-capabilities') ) {
        // do nothing
      } else {

        if ( jQuery('body').hasClass('home') ) {
          extraHeight = 0;
        } else {
          extraHeight = 0;
        }

        // Hide Header on on scroll down
        var didScroll;
        var lastScrollTop = 0;
        var delta = 5;
        var navbarHeight = jQuery('.site-header').outerHeight() + extraHeight;

        jQuery(window).scroll(function(event){
          didScroll = true;
        });

        setInterval(function() {
          if (didScroll) {
            hasScrolled();
            didScroll = false;
          }
        }, 250);

        function hasScrolled() {
          var st = jQuery(this).scrollTop();

          // Make sure they scroll more than delta
          if(Math.abs(lastScrollTop - st) <= delta)
          return;

          // If they scrolled down and are past the navbar, add class .nav-up.
          // This is necessary so you never see what is "behind" the navbar.
          if (st > lastScrollTop && st > navbarHeight){
          // Scroll Down
            jQuery('.site-header').removeClass('nav-down').addClass('nav-up');
          } else {
            // Scroll Up
           if(st + jQuery(window).height() < jQuery(document).height()) {
              jQuery('.site-header').removeClass('nav-up').addClass('nav-down');
            }
         }
        lastScrollTop = st;
        }
      }
    };

    return {
        init: function() {
          goSticky();
          if ( jQuery('body').hasClass('page-template-page-capabilities') ) {
            permanentSticky();
          }
          if ( jQuery('body').hasClass('page-template-page-contact') ) {
            adjustLayout();
          }
          return this; 
        },
        events: function() {
            Events.bind( "scroll" ).to(checkTop, {context: this});
            return this;
        }
    };
}();