App.Modules = App.Modules || {};
App.Modules.Navigation = function () {
    var o = { };

    var openMobileMenu = function(data) {
        jQuery("#menu-wrapper").slideDown('1000', function() { 
            jQuery('#menu-wrapper').css('display', 'flex');
        });
        jQuery('body').addClass('no-scroll');

        // ios scroll prevent
        document.ontouchmove = function (e) {
          e.preventDefault();
        }
    };

    var closeMobileMenu = function(data) {
        jQuery("#menu-wrapper").slideUp('1000', function() { 
            jQuery('#menu-wrapper').css('display', 'none');
        });
        jQuery('body').removeClass('no-scroll');

        // ios scroll prevent
        document.ontouchmove = function (e) {
          return true;
        }
    };

    return {
        init: function() { return this; },
        events: function() {
            Events.bind("click", "#js-mobile-menu-open").to(openMobileMenu, this);
            Events.bind("click", "#js-mobile-menu-close").to(closeMobileMenu, this);
            return this;
        }
    };
}();