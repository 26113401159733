App.Modules = App.Modules || {};
App.Modules.projects = function () {

    var projectsDropdownNav = function(data) {
      var url = jQuery(data.elem).val(); // get selected value
      if (url) { // require a URL
          window.location = url; // redirect
      }
      return false;
    };

    return {
        init: function() { 
          return this; 
        },
        events: function() {
            Events.bind("change", "#mobile-project-sorter").to(projectsDropdownNav, this);
            return this;
        }
    };
}();